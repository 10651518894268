import React, {FC, memo, useCallback, useMemo, useState} from 'react'
import {Paper} from '@mui/material'
import {TISOMonth, TTransaction} from '6-shared/types'
import {useToggle} from '6-shared/hooks/useToggle'

import {useAppSelector} from 'store/index'
import {TEnvelopeId} from '5-entities/envelope'

import {Parent} from './Parent'
import {Row} from './Row'
import {Header} from './Header'
import {useExpandEnvelopes} from './models/useExpandEnvelopes'
import {Group} from './Group'
import {shallowEqual} from "react-redux";
import {toISOMonth} from "../../../6-shared/helpers/date";
import {useIsSmall} from "./shared/shared";
import {getMergedTransactionsMonths, mergedTransactionsData} from "../../../store/rocketData";
import {balances} from "5-entities/envBalances";
import useTableDefaultTemplate, {TemplatedGroup} from "../table-template";

type TagTableProps = {
    className?: string
    onOpenDetails: (id: TEnvelopeId) => void
    onShowTransactions: (list: TTransaction[]) => void
    onMonthNameClick: (name: string) => void
    onGroupClick: (group: any) => void
}

const PnlReportTable: FC<TagTableProps> = props => {
    const {
        className,
        onOpenDetails,
        onShowTransactions,
        onMonthNameClick,
        onGroupClick
    } = props

    const {expanded, toggle, expandAll, collapseAll} = useExpandEnvelopes()
    const [showAll, toggleShowAll] = useToggle()
    const [reorderMode, toggleReorderMode] = useToggle()


    const allTransactions = useAppSelector(mergedTransactionsData)

    const allMonths = useAppSelector(getMergedTransactionsMonths)

    const defaultMonthsArray = useMemo(() => {
        const currentMonth = toISOMonth(new Date())
        // @ts-ignore
        const index = allMonths.indexOf(currentMonth)
        if (allMonths.length < 2) return [currentMonth]
        if (index === -1) return [allMonths.at(-2), allMonths.at(-1)]
        if (index === 0) return [currentMonth, allMonths.at(1)]
        if (index >= 1) return [currentMonth, allMonths.at(index - 1)]
        else return allMonths
        //  return allMonths

    }, [allMonths])
    const [monthList, setMonthList] = useState(defaultMonthsArray)

    const onMonthsChange = useCallback((newMonthList: TISOMonth[]) => {
        setMonthList(newMonthList);
    }, [setMonthList])

    const months = useMemo(() => {
        return monthList?.length > 0 ? monthList.sort() : defaultMonthsArray.sort();
    }, [monthList, defaultMonthsArray])


    // const renderInfo = useEnvRenderInfo(toISOMonth(new Date()))

    const collectTransactionsListForDrawer = useCallback((ids: TEnvelopeId[], isExact: boolean = false) => {
        //@ts-ignore
        return allTransactions.filter(tr => ids.includes(tr.id))
    }, [allTransactions])


    const onShowExactTransactions = useCallback(
        (ids: TEnvelopeId[]) => {
            const list = collectTransactionsListForDrawer(ids, true)
            onShowTransactions(list)
        },
        [collectTransactionsListForDrawer, onShowTransactions]
    )

    const onShowAllTransactions = useCallback((ids: TEnvelopeId[]) => {
            const list = collectTransactionsListForDrawer(ids)
            onShowTransactions(list)
        },
        [collectTransactionsListForDrawer, onShowTransactions]
    )
    const monthListZero = balances.useMonthList()
//@ts-ignore
    const handleMonthNameClick = (month) => {
        const whichMonth = monthListZero.includes(month) ? month : toISOMonth(new Date())
        onMonthNameClick(whichMonth)
    }

    // const mergeData1 = useMergeRocketData();
    const defaultPLTemplateStructure = useTableDefaultTemplate()
    const [templatedStructure, setTemplatedStructure] = useState(defaultPLTemplateStructure)
//@ts-ignore
    const filterTrByMonths = useCallback((values) => {
        //@ts-ignore
        return values.filter(tr => monthList.includes(tr.date))
    }, [monthList])

    // @ts-ignore
    const findGroupById = useCallback((id, structure) => {
        for (let group of structure) {
            if (group.id === id) {
                return group;
            } else if (group.sub_group?.length) {
                //@ts-ignore
                const found = findGroupById(id, group.sub_group);
                if (found) return found;
            }
        }
        return null;
    }, [])

    const moveEnvelope = (envelopeId: TEnvelopeId, fromGroupId: string, toGroupId: string) => {
        setTemplatedStructure(prevState => {
            const newState = [...prevState]; // Копируем текущее состояние

            //@ts-ignore // Рекурсивная функция для поиска и удаления объекта по envelopeId внутри values_source
            const findAndRemoveEnvelope = (sources, id) => {
                for (let i = 0; i < sources.length; i++) {
                    const source = sources[i];

                    // Если это искомый объект
                    if (source.id === id) {
                        const [removedEnvelope] = sources.splice(i, 1); // Удаляем его и возвращаем
                        return removedEnvelope;
                    }

                    // Если у объекта есть дети, ищем глубже
                    if (source.children && source.children.length) {
                        //@ts-ignore
                        const foundInChildren = findAndRemoveEnvelope(source.children, id);
                        if (foundInChildren) return foundInChildren;
                    }
                }
                return null; // Если объект не найден
            };

            // Найти fromGroup
            const fromGroup = findGroupById(fromGroupId, newState);
            // Найти toGroup
            const toGroup = findGroupById(toGroupId, newState);

            if (!fromGroup || !toGroup) {
                console.error('Invalid fromGroupId or toGroupId');
                return prevState;
            }

            // Найти и удалить объект с envelopeId внутри fromGroup.values_source
            const movedEnvelope = findAndRemoveEnvelope(fromGroup.values_source, envelopeId);

            if (!movedEnvelope) {
                console.error('Envelope not found');
                return prevState;
            }

            // Добавить объект в toGroup.values_source
            toGroup.values_source.push(movedEnvelope);

            return newState; // Обновляем состояние
        });
    };

    const onValueChange = (date: TISOMonth, value: any, groupId: string) => {

        setTemplatedStructure(prevState => {
            const newState = [...prevState]; // Копируем текущее состояние
            const group = findGroupById(groupId, newState); // Найти group

            group.values.find((tr: { date: TISOMonth }) => tr.date === date).totalActivity = value;
            return newState; // Обновляем состояние
        })
    }

    const openGroupSidebar = (id: any) => {
        const group = findGroupById(id, templatedStructure)
        onGroupClick(group)
    }

    function renderCategories(categories: any[], groupID: string) {
        return categories.map(parent => {

            let renderChildren = parent.children
                .map((child: { id: TEnvelopeId; values: any }, idx: number, arr: string | any[]) => (
                    <Row
                        isSelf={false}
                        key={'child' + child.id}
                        id={child.id}
                        values={filterTrByMonths(child.values)}
                        isLastVisibleChild={idx === arr.length - 1}
                        isDefaultVisible={true}
                        isReordering={reorderMode}
                        openTransactionsPopover={onShowExactTransactions}
                        openDetails={onOpenDetails}
                        valuesKey={"selfActivity"}
                        moveEnvelope={moveEnvelope}
                        templateGroupId={groupID}
                    />
                ))

            if (!!parent.children.length) {
                renderChildren = [
                    <Row
                        isSelf
                        valuesKey={"selfActivity"}
                        key={'self' + parent.id}
                        id={parent.id}
                        values={filterTrByMonths(parent.values)}
                        isReordering={reorderMode}
                        isDefaultVisible={true}
                        openTransactionsPopover={onShowExactTransactions}
                        openDetails={onOpenDetails}
                        moveEnvelope={moveEnvelope}
                        templateGroupId={groupID}
                    />,
                    ...renderChildren,
                ]
            }

            const isExpanded =
                !!renderChildren.length && expanded.includes(parent.id)
            // !!renderChildren.length

            return (
                <Parent
                    key={parent.id}
                    id={parent.id}
                    isExpanded={isExpanded}
                    onExpandToggle={toggle}
                    onExpandAll={expandAll}
                    onCollapseAll={collapseAll}
                    parent={
                        <Row
                            valuesKey={"totalActivity"}
                            id={parent.id}
                            values={filterTrByMonths(parent.values)}
                            isDefaultVisible={true}
                            isExpanded={isExpanded}
                            onExpandToggle={toggle}
                            isReordering={reorderMode}
                            openDetails={onOpenDetails}
                            openTransactionsPopover={onShowAllTransactions}
                            moveEnvelope={moveEnvelope}
                            templateGroupId={groupID}
                        />
                    }
                    children={renderChildren}
                />
            )
        })
    }

    const calculateTotals = (group: TemplatedGroup) => {
        const totalsByDate = group.values_source.reduce((acc, child) => {
            // @ts-ignore
            child.values.forEach(({date, totalActivity}) => {
                // @ts-ignore
                if (!acc[date]) {
                    // @ts-ignore
                    acc[date] = {};
                }

                Object.keys(totalActivity).forEach(currency => {
                    // @ts-ignore
                    acc[date][currency] = (acc[date][currency] || 0) + totalActivity[currency];
                });
            });
            return acc;
        }, {});

        // Если у группы есть суб-группы, рекурсивно собираем их totals
        if (group.sub_group && group.sub_group.length > 0) {
            group.sub_group.forEach(sub => {
                const subTotals = calculateTotals(sub);

                // Суммируем subTotals с текущими totals
                Object.keys(subTotals).forEach(date => {
                    // @ts-ignore
                    if (!totalsByDate[date]) {
                        // @ts-ignore
                        totalsByDate[date] = {}
                    }
                    // @ts-ignore
                    Object.keys(subTotals[date]).forEach(currency => {
                        // @ts-ignore
                        totalsByDate[date][currency] = (totalsByDate[date][currency] || 0) + subTotals[date][currency];
                    });
                });
            });
        }

        return totalsByDate;
    };

    const calculateFormula = (formula: string) => {
        const [operand1Id, operator, operand2Id] = formula.split(' '); // Простое разбиение формулы на части

        // Найти обе группы по их ID
        const operand1Group = findGroupById(operand1Id, templatedStructure);
        const operand2Group = findGroupById(operand2Id, templatedStructure);

        if (!operand1Group || !operand2Group) {
            console.error(`Не удалось найти одну из групп: ${operand1Id} или ${operand2Id}`);
            return [];
        }

        // Обрабатываем значения этих групп
        const operand1Values = operand1Group.values || [];
        const operand2Values = operand2Group.values || [];

        const result: { date: any; totalActivity: {} }[] = [];

        // Совместить значения по датам
        // @ts-ignore
        operand1Values.forEach(({date, totalActivity}) => {
            // @ts-ignore
            const operand2Value = operand2Values.find(v => v.date === date);
            if (!operand2Value) return; // Пропускаем, если для этой даты нет данных во второй группе

            const resultByCurrency = {};

            // Пробегаемся по валютам и применяем оператор
            Object.keys(totalActivity).forEach(currency => {
                const value1 = totalActivity[currency] || 0;
                const value2 = operand2Value.totalActivity[currency] || 0;

                switch (operator) {
                    case '+':
                        // @ts-ignore
                        resultByCurrency[currency] = value1 + value2;
                        break;
                    case '-':
                        // @ts-ignore
                        resultByCurrency[currency] = value1 - value2;
                        break;
                    case '/':
                        // @ts-ignore
                        resultByCurrency[currency] = value2 !== 0 ? value1 / value2 : 0; // Защита от деления на 0
                        break;
                    default:
                        console.error(`Неизвестный оператор ${operator}`);
                }
            });

            result.push({
                date,
                totalActivity: resultByCurrency
            });
        });

        return result;
    };

    const renderGroups = templatedStructure.map((group, groupIdx) => {

        // Обновляем values группы, преобразуем totalsByDate в массив
        // @ts-ignore
        if (group.editable_source) {
            const totalsByDate = calculateTotals(group);
            // @ts-ignore
            group.values = Object.keys(totalsByDate).map(date => ({
                date: date,
                // @ts-ignore
                totalActivity: totalsByDate[date]
            }));
        }
        if (!!group.sub_group.length) {
            group.sub_group.forEach(sub => {
                if (sub.editable_source) {
                    const totalsByDate = calculateTotals(sub);
                    // @ts-ignore
                    sub.values = Object.keys(totalsByDate).map(date => ({
                        date: date,
                        // @ts-ignore
                        totalActivity: totalsByDate[date]
                    }));
                }
            })

        }

        if (group.type === 'formula' && group.formula) {
            group.values = calculateFormula(group.formula)
            if (group.sub_group.length > 0) {
                group.sub_group.forEach(sub => {
                    // @ts-ignore
                    sub.values = calculateFormula(sub.formula)
                })
            }
        }


        return group.sub_group.length > 0 ? (
            <Group
                key={group.id}
                name={group.title}
                values={filterTrByMonths(group?.values)}
                groupIdx={groupIdx}
                isReordering={reorderMode}
                // openTransactionsPopover={onShowAllTransactions}
                isEditable={group.editable_values}
                onValueChange={(date, value) => onValueChange(date, value, group.id)}
                onGroupNameClick={() => openGroupSidebar(group.id)}
            >
                {
                    group.values_source.length > 0 && renderCategories(group.values_source, group.id)
                }
                {
                    group.sub_group.map(sub => (
                        <Group
                            key={sub.id}
                            name={sub.title}
                            values={filterTrByMonths(sub?.values)}
                            groupIdx={groupIdx}
                            isReordering={reorderMode}
                            isSubgroup={true}
                            isEditable={sub.editable_values}
                            onValueChange={(date, value) => onValueChange(date, value, sub.id)}
                            onGroupNameClick={() => openGroupSidebar(sub.id)}
                            // openTransactionsPopover={onShowAllTransactions}
                        >
                            {/*// @ts-ignore*/}
                            {sub.values_source.length > 0 && renderCategories(sub.values_source, sub.id)}
                        </Group>
                    ))
                }
            </Group>
        ) : (
            <Group
                key={group.id}
                name={group.title}
                values={filterTrByMonths(group?.values)}
                groupIdx={groupIdx}
                isReordering={reorderMode}
                isEditable={group.editable_values}
                onValueChange={(date, value) => onValueChange(date, value, group.id)}
                onGroupNameClick={() => openGroupSidebar(group.id)}
                // openTransactionsPopover={onShowAllTransactions}
            >
                {/*// @ts-ignore*/}
                {group.values_source.length > 0 && renderCategories(group.values_source, group.id)}
            </Group>
        );
    });


    const isSmall = useIsSmall()
    return (
        <Paper className={className} elevation={0} sx={{
            position: 'relative', pb: 1,
            width: `${months.length * 100 + (isSmall ? 200 : 300)}px`
        }}>
            {months.length && (
                <Header
                    //@ts-ignore
                    allMonths={allMonths}
                    months={months}
                    isAllShown={showAll}
                    isReordering={reorderMode}
                    onShowAllToggle={toggleShowAll}
                    onReorderModeToggle={toggleReorderMode}
                    onMonthsChange={onMonthsChange}
                    onMonthNameClick={handleMonthNameClick}
                />
            )}
            {/*<NewGroup visible={reorderMode}/>*/}
            {months.length && (renderGroups)}
            {/*<Footer months={months}/>*/}
        </Paper>
    )
}

export const PnlReport = memo(
    (props: TagTableProps) => (
        <PnlReportTable {...props} />
    ),
    shallowEqual
)
