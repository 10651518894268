import React, {FC, useState} from 'react'
import {Box, ButtonBase, Collapse, IconButton, TextField, Typography} from '@mui/material'
import {AddIcon} from '6-shared/ui/Icons'

import {useAppDispatch, useAppSelector} from 'store/index'
import {TableRow} from './shared/shared'
import {TISOMonth} from '6-shared/types'
import {displayCurrency} from '5-entities/currency/displayCurrency'
import {Amount} from '6-shared/ui/Amount'
import {useTranslation} from 'react-i18next'
import {Tooltip} from "../../../6-shared/ui/Tooltip";
import {createEnvelope} from "../../../4-features/envelope/createEnvelope";
import {ExpandActionButton} from "../../FlowOfFunds/EnvelopeTable/ExpandActionButton";
import {TEnvelopeId} from "../../../5-entities/envelope";
import {getDisplayCurrency} from "../../../5-entities/currency/displayCurrency/model";

type TGroupProps = {
    name: string
    children?: React.ReactNode[]
    values: any[]
    groupIdx: number
    prevIdx?: number
    nextIdx?: number
    isReordering: boolean
    isSubgroup?: boolean
    openTransactionsPopover?: (ids: TEnvelopeId[]) => void
    isEditable?: boolean
    onValueChange?: (month: TISOMonth, value: any) => void
    onGroupNameClick?: () => void
}
export const Group: FC<TGroupProps> = ({
                                           name,
                                           groupIdx,
                                           prevIdx,
                                           nextIdx,
                                           isReordering,
                                           isEditable,
                                           isSubgroup,
                                           children,
                                           values,
                                           onValueChange,
                                           onGroupNameClick
                                       }) => {
    const {t} = useTranslation('budgets')
    const dispatch = useAppDispatch()

    const Actions = (

        <Tooltip title={t('createEnvelope')}>
            <IconButton
                onClick={() =>
                    dispatch(createEnvelope({group: name, indexRaw: groupIdx}))
                }
            >
                <AddIcon/>
            </IconButton>
        </Tooltip>
    )

    const [isExpanded, setIsExpanded] = useState(true)

    const GroupNameCell = (
        <Box
            sx={{
                pl: isSubgroup ? 2 : 0,
                pb: 0,
                minWidth: 0,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'flex-end',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            {!!children ? <ExpandActionButton
                    isExpanded={isExpanded}
                    toggleExpanded={e => setIsExpanded(e)}/>
                :
                <Box sx={{width: '28px'}}/>}
            <ButtonBase
                sx={{p: 1, ml: -1, minWidth: 0, flexShrink: 1}}
                onClick={onGroupNameClick}
            >
                <Typography variant="h6" sx={{fontWeight: 900}} noWrap>
                    {name}
                </Typography>
            </ButtonBase>

            {isReordering && Actions}
        </Box>
    )


    const Sum: FC<{ value: any, month: TISOMonth, onClick?: (ids: TEnvelopeId[]) => void }> = ({
                                                                                                      value,
                                                                                                      month,
                                                                                                      onClick
                                                                                                  }) => {
        const toDisplay = displayCurrency.useToDisplay(month)
        const userCurrency = useAppSelector(getDisplayCurrency)

        const [localValue, setLocalValue] = useState(value)

        const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
            setLocalValue({ [userCurrency]: e.target.value } )
        }

        const valueUpdate = () => {
            if (onValueChange) onValueChange(month, localValue)
        }

        return (
            isReordering && isEditable ?
                <TextField
                    size="small"
                    value={toDisplay(localValue)}
                    onChange={changeValue}
                    onBlur={valueUpdate}
                    inputProps={{type: 'tel'}}/>
                :
                <ButtonBase onClick={() => onClick}>
                    <Typography alignSelf={'center'} color="text.primary" component="span"
                                variant="body1" align="center" noWrap
                                style={{
                                    fontWeight: '600',
                                    fontSize: '1.1rem'
                                }}>
                        {/*@ts-ignore*/}
                        <Amount value={toDisplay(localValue)} decMode="ifOnly"/>
                    </Typography>
                </ButtonBase>

        )
    }

    return (
        <>
            {/*{floating.render()}*/}
            <TableRow
                sx={{
                    alignItems: 'center',
                    borderBottom: 'divider',
                }}
                name={GroupNameCell}
                editor={isReordering && <Box sx={{m: 0, width: 200}}/>}

                columns={
                    values.map((activity, index) => (
                        <Sum

                            key={`${index}-161`}
                            value={activity.totalActivity}
                            month={activity.date}
                            // onClick={e => openTransactionsPopover(activity.ids)}
                        />
                    ))
                }
            />
            <Box
                borderBottom={!isExpanded ? 2 : 0}
                borderTop={isExpanded ? 2 : 0}
                borderColor={'divider'}>
                <Collapse in={isExpanded} unmountOnExit>
                    {children}
                </Collapse>
            </Box>
        </>
    )
}

