import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {getTransactionsExtraData} from "./rocketApiMethods";
import {upsertItem} from "store/rocketData";


const useFetchRocketData = () => {

  const dispatch = useAppDispatch();
  const transactions = useAppSelector(state => state.data.current.transaction);
  useEffect(() => {
    const fetchAndUpdate = async () => {
      if (!transactions) return;

      const transactionIds = Object.keys(transactions);
      try {
        const extraData = await getTransactionsExtraData(transactionIds);
        extraData.forEach(item => {
          dispatch(upsertItem(item))
        });
      } catch (error) {
        console.error('Error fetching rocket data:', error);
      }
    };

    fetchAndUpdate();
  }, [transactions, dispatch]);
};

export default useFetchRocketData;
