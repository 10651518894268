import React, {FC, memo, useCallback, useMemo, useState} from 'react'
import {Box, Paper} from '@mui/material'
import {TISOMonth, TTransaction} from '6-shared/types'
import {useToggle} from '6-shared/hooks/useToggle'

import {useAppSelector} from 'store/index'
import {TEnvelopeId} from '5-entities/envelope'

import {Parent} from './Parent'
import {Row} from './Row'
import {Header} from './Header'
import {useExpandEnvelopes} from './models/useExpandEnvelopes'
import {Group} from './Group'
import {NewGroup} from "../../Budgets/EnvelopeTable/NewGroup";
import {shallowEqual} from "react-redux";
import {
  useEnvRenderInfo
} from "../../Budgets/EnvelopeTable/models/envRenderInfo";
import {toISOMonth} from "../../../6-shared/helpers/date";
import {useIsSmall} from "./shared/shared";


import useMergeRocketData
  from "../../../6-shared/api/rocketData/useMergeRocketData";
import {
  getMergedTransactionsMonths,
  mergedTransactionsData
} from "../../../store/rocketData";
import {balances} from "5-entities/envBalances";

type TagTableProps = {
  className?: string
  onOpenDetails: (id: TEnvelopeId) => void
  onShowTransactions: (list: TTransaction[]) => void
  onMonthNameClick: (name: string) => void
}

const PnlReportTable: FC<TagTableProps> = props => {
  const {
    className,
    onOpenDetails,
    onShowTransactions,
    onMonthNameClick
  } = props

  const {expanded, toggle, expandAll, collapseAll} = useExpandEnvelopes()
  const [showAll, toggleShowAll] = useToggle()
  const [reorderMode, toggleReorderMode] = useToggle()


  const allTransactions = useAppSelector(mergedTransactionsData)

  const allMonths = useAppSelector(getMergedTransactionsMonths)

  const defaultMonthsArray = useMemo(() => {
    const currentMonth = toISOMonth(new Date())
    // @ts-ignore
    const index = allMonths.indexOf(currentMonth)
    if (allMonths.length < 2) return [currentMonth]
    if (index === -1) return [allMonths.at(-2), allMonths.at(-1)]
    if (index === 0) return [currentMonth, allMonths.at(1)]
    if (index >= 1) return [currentMonth, allMonths.at(index - 1)]
    else return allMonths
    //  return allMonths

  }, [allMonths])
  const [monthList, setMonthList] = useState(defaultMonthsArray)

  const onMonthsChange = useCallback((newMonthList: TISOMonth[]) => {
    setMonthList(newMonthList);
  }, [setMonthList])

  const months = useMemo(() => {
    return monthList?.length > 0 ? monthList.sort() : defaultMonthsArray.sort();
  }, [monthList, defaultMonthsArray])


  const renderInfo = useEnvRenderInfo(toISOMonth(new Date()))

  const collectTransactionsListForDrawer = useCallback((ids: TEnvelopeId[], isExact: boolean = false) => {
    //@ts-ignore
    return allTransactions.filter(tr => ids.includes(tr.id))
  }, [allTransactions])


  const onShowExactTransactions = useCallback(
    (ids: TEnvelopeId[]) => {
      const list = collectTransactionsListForDrawer(ids, true)
      onShowTransactions(list)
    },
    [collectTransactionsListForDrawer, onShowTransactions]
  )

  const onShowAllTransactions = useCallback((ids: TEnvelopeId[]) => {
      const list = collectTransactionsListForDrawer(ids)
      onShowTransactions(list)
    },
    [collectTransactionsListForDrawer, onShowTransactions]
  )
  const monthListZero = balances.useMonthList()
//@ts-ignore
  const handleMonthNameClick = (month) => {
    const whichMonth = monthListZero.includes(month) ? month : toISOMonth(new Date())
    onMonthNameClick(whichMonth)
  }

  const mergeData = useMergeRocketData();
//@ts-ignore
  const filterTrByMonths = useCallback((values) => {
    //@ts-ignore
    return values.filter(tr => monthList.includes(tr.date))
  }, [monthList])

  const renderGroups = mergeData
    //@ts-ignore
    .map((group, groupIdx) => {
      // @ts-ignore
      const parents = group.children
        //@ts-ignore
        .map(parent => {
          const {isDefaultVisible, showSelf} = renderInfo[parent.id]

          let renderChildren = parent.children
            //@ts-ignore
            .map((child, idx, arr) => (
              <Row
                key={'child' + child.id}
                id={child.id}
                values={filterTrByMonths(child.values)}
                isLastVisibleChild={idx === arr.length - 1}
                isDefaultVisible={isDefaultVisible}
                isReordering={reorderMode}
                openTransactionsPopover={onShowExactTransactions}
                openDetails={onOpenDetails}
                valuesKey={"selfActivity"}
              />
            ))

          if (showSelf) {
            renderChildren = [
              <Row
                isSelf
                valuesKey={"selfActivity"}
                key={'self' + parent.id}
                id={parent.id}
                values={filterTrByMonths(parent.values)}
                isReordering={reorderMode}
                isDefaultVisible={isDefaultVisible}
                openTransactionsPopover={onShowExactTransactions}
                openDetails={onOpenDetails}
              />,
              ...renderChildren,
            ]
          }

          const isExpanded =
            !!renderChildren.length && expanded.includes(parent.id)

          return (
            <Parent
              key={parent.id}
              id={parent.id}
              isExpanded={isExpanded}
              onExpandToggle={toggle}
              onExpandAll={expandAll}
              onCollapseAll={collapseAll}
              parent={
                <Row
                  valuesKey={"totalActivity"}
                  id={parent.id}
                  values={filterTrByMonths(parent.values)}
                  isDefaultVisible={isDefaultVisible}
                  isExpanded={isExpanded}
                  onExpandToggle={toggle}
                  isReordering={reorderMode}
                  openDetails={onOpenDetails}
                  openTransactionsPopover={onShowAllTransactions}
                />
              }
              children={renderChildren}
            />
          )
        })

      return {
        group,
        groupIdx,
        renderChildren: parents,
      }
    })
    //@ts-ignore
    .filter(data => data.renderChildren.length)
    //@ts-ignore
    .map((data, index, array) => {
      const {group, groupIdx, renderChildren} = data
      //@ts-ignore
      const totalsByDate = group.children.reduce((acc, child) => {
        //@ts-ignore
        child.values.forEach(({date, totalActivity}) => {
          if (!acc[date]) {
            acc[date] = {};
          }

          Object.keys(totalActivity).forEach(currency => {
            acc[date][currency] = (acc[date][currency] || 0) + totalActivity[currency];
          });
        });
        return acc;
      }, {});

      group.values = Object.keys(totalsByDate).map(date => ({
        ...group.values,
        date: date,
        totalActivity: totalsByDate[date]
      }));
      const prevVisibleIdx = array[index - 1]?.groupIdx
      const nextVisibleIdx = array[index + 1]?.groupIdx

      return (
        <Group
          key={group.id}
          name={group.id}
          values={filterTrByMonths(group?.values)}
          groupIdx={groupIdx}
          prevIdx={prevVisibleIdx}
          nextIdx={nextVisibleIdx}
          isReordering={reorderMode}
        >
          {renderChildren}
        </Group>
      )
    })

  const isSmall = useIsSmall()
  return (
    <Paper className={className} elevation={0} sx={{
      position: 'relative', pb: 1,
      width: `${months.length * 100 + (isSmall ? 200 : 300)}px`
    }}>
      {months.length && (
        <Header
          //@ts-ignore
          allMonths={allMonths}
          months={months}
          isAllShown={showAll}
          isReordering={reorderMode}
          onShowAllToggle={toggleShowAll}
          onReorderModeToggle={toggleReorderMode}
          onMonthsChange={onMonthsChange}
          onMonthNameClick={handleMonthNameClick}
        />
      )}
      <NewGroup visible={reorderMode}/>
      {months.length && (renderGroups)}
      {/*<Footer months={months}/>*/}
    </Paper>
  )
}

export const PnlReport = memo(
  (props: TagTableProps) => (
    <PnlReportTable {...props} />
  ),
  shallowEqual
)
