import React, {useState, FC, useEffect, ChangeEvent} from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {TDateDraft, TISOMonth} from "../types";
import {formatDate} from "../helpers/date";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// Пропсы компонента
interface MonthMultiSelectProps {
  onChange: (selectedMonths: string[] | TISOMonth[]) => void;
  selectedMonths: TISOMonth[] | string[];
  allMonths:TISOMonth[];
}

export default function  MonthMultiSelect({ selectedMonths, onChange, allMonths }: MonthMultiSelectProps):React.JSX.Element  {
  //@ts-ignore
  const [localSelectedMonths, setLocalSelectedMonths] = useState<TISOMonth[]>(selectedMonths);

  useEffect(() => {
    //@ts-ignore
    setLocalSelectedMonths(selectedMonths);
  }, [selectedMonths]);

  const handleChange = (event: SelectChangeEvent<typeof selectedMonths>) => {
    const newSelectedMonths = event.target.value as TISOMonth[];

    if (!!newSelectedMonths.length && newSelectedMonths.length <= 12 ) {
      setLocalSelectedMonths(newSelectedMonths);
      onChange(newSelectedMonths);
    }
  };

  function getMonthName(month: TDateDraft) {
    return formatDate(month, 'LLL').toUpperCase().slice(0, 3)
  }
  function getYear(month: TDateDraft) {
    return new Date(month).getFullYear()
  }

  const formattedDate = (month:any) => `${getYear(month)} ${getMonthName(month)}`

  return (
    <FormControl sx={{ my: 1, width: 300 }} style={{position: 'sticky'}} size='small'>
      <InputLabel id="month-select-label">Периоды</InputLabel>
      <Select
        labelId="month-select-label"
        id="month-select"
        multiple
        value={localSelectedMonths}
        onChange={handleChange}
        input={<OutlinedInput label="Периоды" />}
        renderValue={(selected) => selected.map(month => formattedDate(month)).join(', ')}
        MenuProps={MenuProps}
        variant={'outlined'}
      >
        {allMonths.map((month) => (
          <MenuItem key={month} value={month}>
            <Checkbox checked={localSelectedMonths.indexOf(month) > -1} />
            <ListItemText primary={formattedDate(month)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
