import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "./index";
import {toISOMonth} from "../6-shared/helpers/date";
import {getMonthList} from "../5-entities/envBalances/1 - monthList";
import {getTransactions} from "../5-entities/transaction/model";

export interface IRocketDataItem {
  transaction_id: string;
  pl_date: string;
  direction: string;
  client_request: string;
}

interface DataArrayState {
  items: IRocketDataItem[];
}

const initialState: DataArrayState = {
  items: []
};

const dataArraySlice = createSlice({
  name: 'rocketData',
  initialState,
  reducers: {
    upsertItem: (state, action: PayloadAction<IRocketDataItem>) => {
      const newItem = action.payload;
      const existingItemIndex = state.items.findIndex(item => item.transaction_id === newItem.transaction_id);
      if (existingItemIndex >= 0) {
        state.items[existingItemIndex] = { ...state.items[existingItemIndex], ...newItem };
      } else {
        state.items.push(newItem);
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter(item => item.transaction_id !== action.payload);
    },
    clearItems: (state) => {
      state.items = [];
    }
  }
});

export const {upsertItem, removeItem, clearItems} = dataArraySlice.actions;

const rocketTransactions = (state: RootState) => state.rocketData.items;

// @ts-ignore
const rocketTransactionsPnlMonthsList = (state: RootState) => state.rocketData.items.map(tr => toISOMonth(tr.pl_date))

export const getMergedTransactionsMonths = createSelector(
  [rocketTransactionsPnlMonthsList, getMonthList],
  (rocketTrMonths, zenTrMonths) => {
    return [...new Set([...rocketTrMonths, ...zenTrMonths].sort())]
  })


export const mergedTransactionsData = createSelector(
  [rocketTransactions, getTransactions],
  (rocketTr, zenTr) => {
    return Object.values(zenTr).map(transaction => {
      const additionalInfo = rocketTr.find(extra => extra.transaction_id === transaction.id)
      return additionalInfo ? {...transaction, ...additionalInfo} : transaction
    })
  })


export default dataArraySlice.reducer;
