import React, {FC, ReactNode, useCallback, useMemo, useState} from 'react'
import { useDroppable } from '@dnd-kit/core'
import { envelopeModel, TEnvelopeId } from '5-entities/envelope'

import { TableRow } from '../shared/shared'
import { NameCell } from './NameCell'
import { ActivityCell } from './ActivityCell'
import {DragTypes} from "../../../Budgets/DnD";
import {ExpandActionButton} from "../../../FlowOfFunds/EnvelopeTable/ExpandActionButton";
import {FormControl, MenuItem, Select} from "@mui/material";

type EnvelopeRowProps = {
  id: TEnvelopeId
  values: any[]
  isSelf?: boolean
  isLastVisibleChild?: boolean
  isExpanded?: boolean
  onExpandToggle?: (id: TEnvelopeId) => void
  isReordering: boolean
  openDetails: (id: TEnvelopeId) => void
  openTransactionsPopover: (ids: TEnvelopeId[]) => void
  isDefaultVisible: boolean
  valuesKey: 'totalActivity' | 'selfActivity'
  templateGroupId: string
  moveEnvelope: (envelopeId: TEnvelopeId, fromGroupId: string, toGroupId: string) => void
}

export const Row: FC<EnvelopeRowProps> = props => {
  const {
    id,
    isSelf,
    isReordering,
    openTransactionsPopover,
    openDetails,
    onExpandToggle,
    values,
    isLastVisibleChild,
    isExpanded,
    isDefaultVisible,
    valuesKey,
    templateGroupId,
    moveEnvelope
  } = props

  const envelope = envelopeModel.useEnvelopes()[id]

  const isChild = !!envelope.parent || !!isSelf
  const isParent = !!envelope.children

  const isExpandable = isExpanded !== undefined && onExpandToggle && !!envelope.children.length

  const handleNameClick = useCallback(() => {
    openDetails(id)
  }, [id, openDetails])

  const TargetGroupForOperationsSelector: FC<{
    currentGroupId: string;
    onTargetGroupIdSelect: (groupId: string) => void;
  }> = ({currentGroupId, onTargetGroupIdSelect}) => {
    const options = [
      {id: 'hidden', name: "Прочие метрики(скрытое)"},
      {id: 'expenses', name: "Расходы(общая)"},
      {id: 'variable_expenses', name: "Переменные расходы"},
      {id: "fixed_expenses", name: "Постоянные расходы"},
      {id: 'other_expenses', name: "Прочие расходы"},
      {id: 'outside_operating_expenses', name: "Вне операционные расходы"}
    ];

    const [selectedOption, setSelectedOption] = useState(options.find(e => e.id === currentGroupId));
//@ts-ignore
    const handleChange = (event) => {
      const newOption = event.target.value;
      setSelectedOption(newOption);
      onTargetGroupIdSelect(newOption.id);
    };

    return (
        <FormControl sx={{m: 0, width: 200}}>
          <Select
              value={selectedOption}
              renderValue={() => selectedOption?.name}
              onChange={handleChange}
              size="small"
              variant="outlined"
          >
            {options.map((option) => (
                //@ts-ignore
                <MenuItem key={option.id} value={option}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
    );
  };

  const onSetTargetGroup = (targetId: string) => {
    moveEnvelope(id, templateGroupId, targetId);
  };

  const handleExpand = () => {
    if (isExpandable) onExpandToggle(id)
  }
  return  (
    <Droppable
      id={id}
      isChild={isChild}
      isLastVisibleChild={!!isLastVisibleChild}
      isExpanded={!!isExpanded}
    >
      <TableRow
        className={'tableRow'}
        sx={{
          cursor: 'pointer',
          borderColor: 'divider',
          borderBottom: 1,
          '&:hover::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'var(--mui-palette-action-hover)',
            zIndex: 3,
            pointerEvents: 'none',
            transition: 'background-color 0.1s ease-in-out',
          },
        }}
        name={
          <>
          {isExpandable && <div style={{position: 'absolute', left: '16px'}}>
            <ExpandActionButton isExpanded={isExpanded}
                                toggleExpanded={e => handleExpand()}/>
          </div>}
          <NameCell
            onClick={handleNameClick}
            envelope={envelope}
            isChild={isChild}
            isParent={isParent}
            isSelf={isSelf}
            isDefaultVisible={isDefaultVisible}
            isReordering={isReordering}
          />
          </>
        }
        editor={ isReordering &&
          <TargetGroupForOperationsSelector
              currentGroupId={templateGroupId}
              onTargetGroupIdSelect={onSetTargetGroup}
          />
        }
        columns={
          values.map((activity, index) => (
            <ActivityCell
              key={activity.date}
              value={activity[valuesKey]}
              month={activity.date}
              onClick={() => openTransactionsPopover(activity.ids)}
            />
          ))
        }
      />
    </Droppable>
  )
}

const Droppable: FC<{
  id: TEnvelopeId
  isChild: boolean
  isLastVisibleChild: boolean
  isExpanded: boolean
  children: ReactNode
}> = props => {
  const { id, isChild, isLastVisibleChild, isExpanded, children } = props
  const { setNodeRef } = useDroppable({
    id: 'envelope-drop' + id + isChild,
    data: { type: DragTypes.envelope, id, isLastVisibleChild, isExpanded },
  })

  return <div ref={setNodeRef}>{children}</div>
}
