import {useAppSelector} from "store";
import {envelopeModel, TGroupNode} from "5-entities/envelope";
import {isEqual} from "lodash";
import {useMemo} from "react";
import {instrumentModel} from "5-entities/currency/instrument";
import {getMergedTransactionsMonths, mergedTransactionsData,} from "store/rocketData";
import {toISOMonth} from "../../helpers/date";
import {TISOMonth} from "../../types";
//@ts-ignore
function processValues(values) {
    // Обрабатываем массив values и суммируем income и outcome по валютам
    //@ts-ignore
    return values.map(value => {
        const income = value.income || {};
        const outcome = value.outcome || {};

        const selfActivity = {};

        // Рассчитываем selfActivity для каждой валюты
        for (let currency in income) {
            //@ts-ignore
            selfActivity[currency] = (selfActivity[currency] || 0) + income[currency];
        }
        for (let currency in outcome) {
            //@ts-ignore
            selfActivity[currency] = (selfActivity[currency] || 0) - outcome[currency];
        }

        // Добавляем selfActivity в values
        value.selfActivity = selfActivity;
        value.childrenActivity = {};
        value.totalActivity = {...selfActivity};
        return value;
    });
}

function mergeActivities(activity1:any[], activity2:any[]) {
    // Суммирует два объекта активности
    const result = {...activity1};

    for (let currency in activity2) {
        result[currency] = (result[currency] || 0) + activity2[currency];
    }

    return result;
}

function mergeIds(ids1:any[], ids2:any[]) {
    // Объединяет два массива ids, удаляя дубликаты
    return Array.from(new Set([...ids1, ...ids2]));
}

function calculateActivity(node:any) {
    // Обработка собственных значений
    if (node.values && node.values.length > 0) {
        node.values = processValues(node.values);
    }

    // Если есть дочерние элементы, рекурсивно обрабатываем их
    if (node.children && node.children.length > 0) {
        //@ts-ignore
        node.children.forEach(child => {
            calculateActivity(child);

            // Для каждой даты в дочерних элементах суммируем активности
            //@ts-ignore
            child.values.forEach(childValue => {
                const date = childValue.date;
                const childSelfActivity = childValue.selfActivity;

                // Ищем соответствующую запись по дате в текущем объекте
                //@ts-ignore
                let parentValue = node.values.find(v => v.date === date);

                if (!parentValue) {
                    // Если такой даты нет у родителя, создаем пустой объект для этой даты
                    parentValue = {
                        date,
                        ids: [],
                        income: {},
                        outcome: {},
                        selfActivity: {},
                        childrenActivity: {},
                        totalActivity: {}
                    };
                    node.values.push(parentValue);
                }

                // Суммируем selfActivity дочерних элементов в childrenActivity родителя
                parentValue.childrenActivity = mergeActivities(parentValue.childrenActivity, childSelfActivity);

                // Обновляем totalActivity (сумма selfActivity и childrenActivity)
                parentValue.totalActivity = mergeActivities(parentValue.selfActivity, parentValue.childrenActivity);

                parentValue.ids = mergeIds(parentValue.ids, childValue.ids);
            });
        });
    }
}

function calcTotals(structure:any) {
    //@ts-ignore
    structure.forEach((node) => {
        // Вызываем calculateActivity для каждой ноды
        calculateActivity(node);

        // Проверяем тип 'group' и очищаем values, если нужно
        if (node.type === 'group') {
            node.values = [];
        }
    });
    return structure
}

const emptyTransactions = (month:TISOMonth) => {
    return {
        date: month,
        ids: [],
        income: {},
        outcome: {},
        selfActivity: {}
    }
}

// Main hook to merge rocket data
const useMergeRocketData = () => {
    const structure:TGroupNode[] = useAppSelector(envelopeModel.getEnvelopeStructure, isEqual);
    const instruments = instrumentModel.useInstruments();
    const allMonths = useAppSelector(getMergedTransactionsMonths)

    const mergedTransactions = useAppSelector(mergedTransactionsData)
    const transformedTransactions = useMemo(() => {

        const result = {};


        mergedTransactions.forEach((transaction) => {
                //@ts-ignore
            const dateKey = toISOMonth(transaction.pl_date ?? transaction.date);
            const envelopeTag = transaction.tag ? transaction.tag[0] : transaction.merchant;

            // Инициализируем массив для конверта, если он еще не создан
            //@ts-ignore
            if (!result[envelopeTag]) {result[envelopeTag] = [];}

            // Проверяем, есть ли уже объект с таким dateKey
            //@ts-ignore
            let existingEntry = result[envelopeTag].find(entry => entry.date === dateKey);

            if (!existingEntry) {
                // Если нет, создаем новый объект
                existingEntry = emptyTransactions(dateKey);
                //@ts-ignore
                result[envelopeTag].push(existingEntry);
            }

            // Добавляем ID транзакции
            existingEntry.ids.push(transaction.id);

            // Суммируем по валюте доходы
            const incomeInstrument = instruments[transaction.incomeInstrument]?.shortTitle;
            if (transaction.income > 0) {
                if (!existingEntry.income[incomeInstrument]) {
                    existingEntry.income[incomeInstrument] = 0;
                }
                existingEntry.income[incomeInstrument] += transaction.income;
            }

            // Суммируем по валюте расходы
            const outcomeInstrument = instruments[transaction.outcomeInstrument]?.shortTitle;
            if (transaction.outcome > 0) {
                if (!existingEntry.outcome[outcomeInstrument]) {
                    existingEntry.outcome[outcomeInstrument] = 0;
                }
                existingEntry.outcome[outcomeInstrument] += transaction.outcome;
            }
        });

        // Дополняем каждый конверт недостающими месяцами
        Object.keys(result).forEach(tag => {
            allMonths.forEach(month => {
                //@ts-ignore
                if (!result[tag].find(entry => entry.date === month)) {
                    //@ts-ignore
                    result[tag].push(emptyTransactions(month));
                }
            });


            // Сортируем массив по дате
            //@ts-ignore
            result[tag].sort((a, b) => new Date(a.date) - new Date(b.date));
        });

        return result;
    }, [allMonths, instruments, mergedTransactions]);


    const mergeData = useMemo(() => {

        const fillEnvelopesStructureWithTransactions = (id: string) => {
            const envelopeId = id.includes('#') ? id.split('#')[1] : null
            //@ts-ignore
            return envelopeId ? transformedTransactions[envelopeId] : []
        };

        // @ts-ignore
        const buildStructure = (nodes: any[]) => {
            //@ts-ignore
            return nodes.map(node => {
                const values = fillEnvelopesStructureWithTransactions(node.id) || allMonths.map(m => emptyTransactions(m))
                return {
                    ...node,
                    values,
                    children: buildStructure(node.children || [])
                };
            });
        };

        const builtStr = buildStructure(structure)
        return calcTotals(builtStr);

    }, [allMonths, structure, transformedTransactions]);


    return mergeData;
};

export default useMergeRocketData;
