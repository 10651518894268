import useMergeRocketData from "6-shared/api/rocketData/useMergeRocketData";
import {useAppSelector} from "../../store";
import {getDisplayCurrency} from "../../5-entities/currency/displayCurrency/model";
import {getMergedTransactionsMonths} from "../../store/rocketData";
import {TISOMonth} from "../../6-shared/types";
import { TEnvNode, TGroupNode} from "../../5-entities/envelope";


export type TemplatedGroup = {
    id: string; // Уникальный идентификатор группы
    type: "formula" | "template_group" | 'template_sub_group'; // Тип группы: формула или обычная
    title: string; // Название группы
    parent: string | null; // Родительская группа, если есть
    sub_group: TemplatedGroup[]; // Вложенные подгруппы
    values: TemplatedValues[]; // Рассчитанные значения для группы
    values_source: TEnvNode[] | TemplatedValues[]; // Источники значений для группы
    editable_values: boolean; // Можно ли редактировать значения группы
    editable_source: boolean; // Можно ли редактировать источники значений группы
    formula?: string; // Формула для вычисления значений, если это формульная группа
};

type TemplatedValues = {
    date: TISOMonth; // Дата значения
    totalActivity: Record<string, number>; // Значения по каждой валюте (например, USD, EUR и т.д.)
};

const useTableDefaultTemplate = () => {
    const envelopesStructure:TGroupNode[] = useMergeRocketData()
    const allMonths:TISOMonth[] = useAppSelector(getMergedTransactionsMonths)
    const userCurrency = useAppSelector(getDisplayCurrency)
    const createInitialGroupValues = () => {
        return allMonths.map((month) => {
            return {
                date: month,
                totalActivity: {[userCurrency]: 0}
            }
        })
    }

    const defaultPLTemplateStructure: TemplatedGroup[] = [
        {
            id: "hidden",
            type: "template_group",
            title: "Доп.метрики",
            parent: null,
            sub_group: [],
            values: [],
            values_source: envelopesStructure
                .filter(e => e.id === 'Приход')
                .map(env => env.children)
                .reduce((acc, childArray) => [...acc, ...childArray], []),
            editable_values: false,
            editable_source: false,
            formula: undefined
        },
        {
            id: "revenue",
            type: "template_group",
            title: "Выручка",
            parent: null,
            sub_group: [],
            values: createInitialGroupValues(),
            values_source: [],
            editable_values: true,
            editable_source: false,
            formula: undefined
        },
        {
            id: "cost_price",
            type: "template_group",
            title: "Себестоимость",
            parent: null,
            sub_group: [{
                id: "operating_costs",
                type: "template_sub_group",
                title: "Операционные затраты",
                parent: "cost_price",
                sub_group: [],
                values: createInitialGroupValues(),
                values_source: [],
                editable_values: true,
                editable_source: false,
                formula: undefined
            }],
            values: createInitialGroupValues(),
            values_source: [],
            editable_values: true,
            editable_source: false,
            formula: undefined
        },
        {
            id: "gross_profit",
            type: "formula",
            title: "Валовая прибыль",
            parent: null,
            sub_group: [
                {
                    id: "gross_profit_share",
                    type: "formula",
                    title: "% валовой прибыли",
                    parent: 'gross_profit',
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: false,
                    formula: "gross_profit / revenue",
                },
            ],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: false,
            formula: "revenue - cost_price",
        },
        {
            id: "expenses",
            type: "template_group",
            title: "Расходы",
            parent: null,
            values: [],
            values_source: envelopesStructure
                .filter(group => group.id !== 'Приход')
                .map(env => env.children)
                .reduce((acc, childArray) => [...acc, ...childArray], []),
            editable_values: false,
            editable_source: true,
            formula: undefined,
            sub_group: [
                {
                    id: "variable_expenses",
                    type: "template_sub_group",
                    title: "Переменные расходы",
                    parent: "expenses",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: true,
                    formula: undefined,
                },
                {
                    id: "fixed_expenses",
                    type: "template_sub_group",
                    title: "Постоянные расходы",
                    parent: "expenses",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: true,
                    formula: undefined,
                }
            ],
        },
        {
            id: "ebitda",
            type: "formula",
            title: "Операционная прибыль (EBITDA)",
            parent: null,
            sub_group: [
                {
                    id: "ebitda_share",
                    type: "formula",
                    title: "% EBITDA",
                    parent: "ebitda",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: false,
                    formula: "ebitda / gross_profit",
                },
            ],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: false,
            formula: "gross_profit - expenses",
        },
        {
            id: "other_expenses",
            type: "template_group",
            title: "Прочие расходы",
            parent: null,
            sub_group: [],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: true,
            formula: undefined,
        },
        {
            id: "net_profit",
            type: "formula",
            title: "Чистая прибыль",
            parent: null,
            sub_group: [
                {
                    id: "net_profit_share",
                    type: "formula",
                    title: "% Чистой прибыли",
                    parent: "net_profit",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: false,
                    formula: "net_profit / revenue",
                },
            ],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: false,
            formula: "ebitda - other_expenses",
        },
        {
            id: "outside_operating_expenses",
            type: "template_group",
            title: "Вне операционные расходы",
            parent: null,
            sub_group: [],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: true,
            formula: undefined,
        },
        {
            id: "retained_earnings",
            type: "formula",
            title: "Нераспределенная прибыль",
            parent: "net_profit",
            sub_group: [],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: false,
            formula: "net_profit - outside_operating_expenses",
        },
    ]

    return defaultPLTemplateStructure
}


export default useTableDefaultTemplate
